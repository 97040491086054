import { DateTime } from "luxon";
import { CSSProperties, useMemo, useState } from "react";
import { UptimeGraphPoint } from "../../api/api/uptime-graphs";
import { GlassCard } from "../../components/GlassCard";

export const UptimeGraph = ({
  graphPoints,
  style,
  className,
}: {
  graphPoints: UptimeGraphPoint[];
  style?: CSSProperties;
  className?: string;
}) => {
  const graphPointsWithExtraData = useMemo(() => {
    return graphPoints
      .map((gp) => ({
        ...gp,
        startTimeStr: DateTime.fromMillis(gp.startTime).toFormat(
          "LLL dd HH:mm:ss",
        ),
        endTimeStr: DateTime.fromMillis(gp.endTime - 1000).toFormat(
          "LLL dd HH:mm:ss",
        ),
      }))
      .sort((gp1, gp2) => gp1.startTime - gp2.startTime);
  }, [graphPoints]);

  const [hoverStartTime, setHoverStartTime] = useState(-1);

  return (
    <div
      className={`${className ? className : ""}`}
      style={{
        display: "flex",
        flexDirection: "row",
        ...(style || {}),
      }}
    >
      {graphPointsWithExtraData.map((p) => {
        const strokeColor =
          p.totalHealthchecks === 0
            ? "#939393"
            : p.passedHealthchecks >= p.totalHealthchecks
              ? "#00CE5F"
              : "#EE4502";
        const isHovered = p.startTime === hoverStartTime;
        const failedHealthchecks = p.totalHealthchecks - p.passedHealthchecks;
        return (
          <div
            key={`GP_${p.startTime}`}
            style={{
              height: "38px",
              flex: 1,
              display: "flex",
              flexDirection: "row",
              position: "relative",
              zIndex: isHovered ? "1000" : undefined,
            }}
          >
            <div
              style={{
                flex: 12.5,
              }}
            />
            <div
              onMouseEnter={() => {
                setHoverStartTime(p.startTime);
              }}
              onMouseLeave={() => {
                setHoverStartTime(-1);
              }}
              style={{
                height: "100%",
                flex: 75,
                background: `linear-gradient(90deg, #000000, #000000) padding-box, linear-gradient(110deg, ${strokeColor}${isHovered ? "FF" : "CC"}, ${strokeColor}00) border-box`,
                borderRadius: "2px",
                border: "1px solid transparent",
                transform: isHovered ? "scale(1.1)" : "unset",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                flex: 12.5,
              }}
            />
            {isHovered && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "170px",
                    left: "50%",
                    marginTop: "1em",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      left: "-50%",
                    }}
                  >
                    <GlassCard>
                      <div
                        style={{
                          padding: "0.7em 1em",
                          fontSize: "0.8em",
                        }}
                      >
                        <p>
                          {p.startTimeStr}&ndash;
                          <br />
                          {p.endTimeStr}
                        </p>
                        <p>
                          Total Healthchecks:{" "}
                          {p.totalHealthchecks.toLocaleString()}
                          <br />
                          Passed: {p.passedHealthchecks.toLocaleString()}
                          <br />
                          <span
                            style={{
                              color:
                                failedHealthchecks > 0 ? "#FF6363" : undefined,
                            }}
                          >
                            Failed: {failedHealthchecks.toLocaleString()}
                          </span>
                        </p>
                      </div>
                    </GlassCard>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
