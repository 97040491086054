import { CSSProperties } from "react";
import styles from "./Footer.module.css";

export const Footer = ({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <div
      className={`${className || ""} ${styles.footer}`}
      style={{
        fontSize: "0.8em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        ...(style || {}),
      }}
    >
      <p>
        Copyright © 2024{" "}
        <a
          href="https://www.linkedin.com/in/sidvenu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Siddharth Venu
        </a>
        . All Rights Reserved.
      </p>
      <p>
        Support: <a href="mailto:admiral@periscope.sh">admiral@periscope.sh</a>
      </p>
      <p>
        <a
          href="/terms-and-conditions.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        &nbsp;·&nbsp;
        <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        &nbsp;·&nbsp;
        <a
          href="/cancellations-and-refunds-policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cancellations and Refunds Policy
        </a>
      </p>
    </div>
  );
};
