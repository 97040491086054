import { Route, Routes } from "react-router-dom";
import { CreateApiPage } from "./CreateApiPage";
import { ListApiPage } from "./ListApiPage";
import { SingleApiPage } from "./SingleApiPage";

export const ApiRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ListApiPage />} />
      <Route path="/new" element={<CreateApiPage />} />
      <Route path="/:id" element={<SingleApiPage />} />
    </Routes>
  );
};
