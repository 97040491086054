import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { PERISCOPE_TOKEN_KEY, USER_ACCOUNT_ID_KEY } from "../api";
import { getUserAccount, login } from "../api/user-account";
import {
  getUserAccountExecutionStats,
  getUserAccountStats,
} from "../api/user-account/stats";
import { getUserSubscriptionInfo } from "../api/user-account/subscription";

export interface UserInfo {
  id: number;
  name: string;
  email: string;
  companyName: string;
}

export interface UserStats {
  numApisTotal: number;
  numApisHealthyNow: number;
  numApisUnhealthyNow: number;
  numApisCompletelyHealthy48hrs: number;
  numApisUnhealthy48hrs: number;
  numApiHelathcheckExecutionsTotal: number;
}

export enum SubscriptionPlanProvider {
  LemonSqueezy = "LemonSqueezy",
  Paddle = "Paddle",
}

export interface SubscriptionPlanInfo {
  id: number;
  healthcheckMinIntervalMs: number;
  healthcheckMaxNumApis: number;
  healthcheckReqDataLimitBytes: number;
  healthcheckMaxTimeoutMs: number;
  provider: SubscriptionPlanProvider;
  providerId: string;
}

export enum SubscriptionStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Overdue = "Overdue",
  Inactive = "Inactive",
}

export interface UserSubscriptionInfo {
  id: number;
  startedAt: number;
  endedAt?: number;
  plan: SubscriptionPlanInfo;
  status: SubscriptionStatus;
  providerId: string;
}

export interface UserState {
  info: UserInfo;
  stats: UserStats;
  subscriptionInfo: UserSubscriptionInfo;
  login: (email: string, password: string) => Promise<void>;
  fetchUserInfo: () => Promise<void>;
  fetchUserStats: () => Promise<void>;
  fetchUserExecutionStats: () => Promise<void>;
  fetchUserSubscriptionInfo: () => Promise<void>;
}

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        info: {
          id: 0,
          name: "",
          email: "",
          companyName: "",
        },
        stats: {
          numApisTotal: 0,
          numApisHealthyNow: 0,
          numApisUnhealthyNow: 0,
          numApisCompletelyHealthy48hrs: 0,
          numApisUnhealthy48hrs: 0,
          numApiHelathcheckExecutionsTotal: 0,
        },
        subscriptionInfo: {
          id: 0,
          startedAt: 0,
          plan: {
            id: 0,
            healthcheckMinIntervalMs: 0,
            healthcheckMaxNumApis: 0,
            healthcheckReqDataLimitBytes: 0,
            healthcheckMaxTimeoutMs: 0,
            provider: SubscriptionPlanProvider.Paddle,
            providerId: "",
          },
          status: SubscriptionStatus.Inactive,
          providerId: "",
        },
        login: async (email, password) => {
          const res = await login(email, password);
          if (res.status === "Success") {
            set({
              info: {
                id: res.id,
                name: res.name,
                email: res.email,
                companyName: res.company_name,
              },
              stats: {
                numApisTotal: 0,
                numApisHealthyNow: 0,
                numApisUnhealthyNow: 0,
                numApisCompletelyHealthy48hrs: 0,
                numApisUnhealthy48hrs: 0,
                numApiHelathcheckExecutionsTotal: 0,
              },
            });
            localStorage.setItem(PERISCOPE_TOKEN_KEY, res.token);
            localStorage.setItem(USER_ACCOUNT_ID_KEY, res.id.toString());
          } else {
            // @todo handle error
          }
        },
        fetchUserInfo: async () => {
          const res = await getUserAccount();
          set({
            info: {
              id: res.id,
              name: res.name,
              email: res.email,
              companyName: res.company_name,
            },
          });
        },
        fetchUserStats: async () => {
          const res = await getUserAccountStats();
          set((state) => ({
            stats: {
              ...state.stats,
              numApisTotal: res.num_apis_total,
              numApisHealthyNow: res.num_apis_healthy_now,
              numApisUnhealthyNow: res.num_apis_unhealthy_now,
              numApisCompletelyHealthy48hrs:
                res.num_apis_completely_healthy_48hrs,
              numApisUnhealthy48hrs: res.num_apis_unhealthy_48hrs,
            },
          }));
        },
        fetchUserExecutionStats: async () => {
          const res = await getUserAccountExecutionStats();
          set((state) => ({
            stats: {
              ...state.stats,
              numApiHelathcheckExecutionsTotal:
                res.num_api_healthcheck_executions_total,
            },
          }));
        },
        fetchUserSubscriptionInfo: async () => {
          const res = await getUserSubscriptionInfo();
          set({
            subscriptionInfo: {
              id: res.id,
              startedAt: res.started_at,
              endedAt: res.ended_at,
              plan: {
                id: res.plan.id,
                healthcheckMinIntervalMs: res.plan.healthcheck_min_interval_ms,
                healthcheckMaxNumApis: res.plan.healthcheck_max_num_apis,
                healthcheckReqDataLimitBytes:
                  res.plan.healthcheck_req_data_limit_bytes,
                healthcheckMaxTimeoutMs: res.plan.healthcheck_max_timeout_ms,
                provider: res.plan.provider,
                providerId: res.plan.provider_id,
              },
              status: res.status,
              providerId: res.provider_id,
            },
          });
        },
      }),
      {
        name: "user-storage",
      },
    ),
  ),
);
