import { useEffect } from "react";
import { useUserStore } from "../../store/user";

export const ApiReport = () => {
  const [userStats, fetchUserStats, fetchUserExecutionStats] = useUserStore(
    (state) => [
      state?.stats || {},
      state.fetchUserStats,
      state.fetchUserExecutionStats,
    ],
  );

  useEffect(() => {
    fetchUserStats();
    const interval = setInterval(() => {
      fetchUserStats();
    }, 15000);
    return () => clearInterval(interval);
  }, [fetchUserStats]);

  useEffect(() => {
    fetchUserExecutionStats();
    const interval = setInterval(() => {
      fetchUserExecutionStats();
    }, 10000);
    return () => clearInterval(interval);
  }, [fetchUserExecutionStats]);

  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        fontSize: "0.8em",
      }}
    >
      <p
        style={{
          marginTop: "3em",
        }}
      >
        # of APIs: {userStats.numApisTotal.toLocaleString()}
      </p>
      <p
        style={{
          marginTop: "1.5em",
        }}
      >
        # of APIs completely healthy in the last 48 hours:{" "}
        {userStats.numApisCompletelyHealthy48hrs.toLocaleString()}
      </p>
      <p
        style={{
          color: userStats.numApisUnhealthy48hrs > 0 ? "#FF6363" : undefined,
        }}
      >
        # of APIs unhealthy in the last 48 hours:{" "}
        {userStats.numApisUnhealthy48hrs.toLocaleString()}
      </p>
      <p
        style={{
          marginTop: "1.5em",
        }}
      >
        # of APIs healthy now: {userStats.numApisHealthyNow.toLocaleString()}
      </p>
      <p
        style={{
          color: userStats.numApisUnhealthyNow > 0 ? "#FF6363" : undefined,
        }}
      >
        # of APIs unhealthy now:{" "}
        {userStats.numApisUnhealthyNow.toLocaleString()}
      </p>
      <p
        style={{
          marginTop: "1.5em",
        }}
      >
        # of Healthcheck Executions (lifetime):{" "}
        {userStats.numApiHelathcheckExecutionsTotal.toLocaleString()}
      </p>
    </div>
  );
};
