import { DashboardPage } from "./dashboard";
import { LandingPage } from "./landing";

function App() {
  const isLandingPage = import.meta.env.VITE_IS_LANDING === "true";

  return isLandingPage ? <LandingPage /> : <DashboardPage />;
}

export default App;
