import ky from "ky";
import toast from "react-hot-toast";

export const PERISCOPE_TOKEN_KEY = "PERISCOPE_TOKEN";
export const USER_ACCOUNT_ID_KEY = "USER_ACCOUNT_ID";

export const api = ky.create({
  prefixUrl: import.meta.env.VITE_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(PERISCOPE_TOKEN_KEY)}`,
    "x-user-account-id": localStorage.getItem(USER_ACCOUNT_ID_KEY) || "",
  },
  hooks: {
    afterResponse: [
      async (_, __, res) => {
        if (res.status === 401) {
          window.location.assign("/login");
        } else if (!res.ok) {
          const message = await res.text();
          toast.error(message || "An error occurred.", {
            duration: 2000,
          });
        }
      },
    ],
  },
});
