import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { patchAction } from "../../../../api/action";
import {
  ApiDto,
  ApiStatus,
  deleteApi,
  getApi,
  patchApi,
} from "../../../../api/api";
import iconEditBlueUrl from "../../../../assets/icon-edit-blue.svg";
import iconPauseWhiteUrl from "../../../../assets/icon-pause-white.svg";
import iconResumeWhiteUrl from "../../../../assets/icon-resume-white.svg";
import iconTrashClosedRedUrl from "../../../../assets/icon-trash-closed-red.svg";
import iconTrashOpenRedUrl from "../../../../assets/icon-trash-open-red.svg";
import { IconButton } from "../../../../components/IconButton";
import { useActionStore } from "../../../../store/action";
import { useApiStore } from "../../../../store/api";
import { ActionsTable } from "../../../components/ActionsTable";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { CopyButton } from "../../../components/CopyButton";
import { Footer } from "../../../components/Footer";
import { GeneralSkeleton } from "../../../components/GeneralSkeleton";

export const SingleApiPage = () => {
  const { id: idParam } = useParams();
  const id = parseInt(idParam || "0");
  const navigate = useNavigate();
  const [trashIcon, setTrashIcon] = useState(iconTrashClosedRedUrl);
  const [apiMiniState, fetchApisList] = useApiStore((state) => [
    state.apisList.find((api) => api.id === id),
    state.fetchApisList,
  ]);
  const [actionsList, fetchActionsList] = useActionStore((state) => [
    state.actionsList || [],
    state.fetchActionsList,
  ]);

  const [apiDto, setApiDto] = useState<ApiDto | undefined>(
    apiMiniState
      ? {
          ...apiMiniState,
          connected_action_ids: [],
          body: "",
          headers: "",
        }
      : undefined,
  );
  const headers = useMemo(() => {
    return JSON.parse(apiDto?.headers || "{}") as {
      [key: string]: string;
    };
  }, [apiDto]);
  const headersStr = useMemo(() => {
    return Object.keys(headers).length > 0
      ? Object.entries(headers)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n")
      : "-";
  }, [headers]);

  const body = useMemo(() => {
    if (
      apiDto?.body &&
      apiDto.body.length < 1024 &&
      headers["content-type"].toLowerCase() === "application/json"
    ) {
      try {
        return JSON.stringify(JSON.parse(apiDto.body), null, 2);
      } catch (e) {
        console.error(`Error in beautifying JSON body`, e);
      }
    }
    return apiDto?.body || "-";
  }, [headers, apiDto]);

  const refreshApi = useCallback(async () => {
    const newApiDto = await getApi(id);
    setApiDto(newApiDto);
  }, [id]);

  useEffect(() => {
    refreshApi();
  }, [refreshApi]);

  useEffect(() => {
    fetchActionsList();
  }, [fetchActionsList]);

  const onPressDelete = async () => {
    await deleteApi(id);
    await fetchApisList();
    navigate("/api");
  };

  const onTogglePause = async () => {
    if (!apiDto) {
      return;
    }
    await patchApi(id, apiDto.status === ApiStatus.Active);
    await Promise.all([refreshApi(), fetchApisList()]);
  };

  if (!apiDto) {
    return <GeneralSkeleton />;
  }

  return (
    <GeneralSkeleton>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            items={[
              {
                url: "/api",
                label: "your apis",
              },
              {
                url: "",
                label: id.toString(),
              },
            ]}
          />
          <IconButton
            style={{
              marginLeft: "2em",
            }}
            onClick={() => onTogglePause()}
            suffixIcon={
              <img
                src={
                  apiDto.status === ApiStatus.Active
                    ? iconPauseWhiteUrl
                    : iconResumeWhiteUrl
                }
                style={{
                  width: "0.8em",
                  aspectRatio: 1,
                }}
              />
            }
          >
            {apiDto.status === ApiStatus.Active ? "pause" : "resume"}
          </IconButton>
          <div
            style={{
              flex: 1,
            }}
          />
          <div
            onMouseEnter={() => setTrashIcon(iconTrashOpenRedUrl)}
            onMouseLeave={() => setTrashIcon(iconTrashClosedRedUrl)}
            style={{
              cursor: "pointer",
              padding: "0.5em",
            }}
            onClick={() => onPressDelete()}
          >
            <img
              src={trashIcon}
              style={{
                width: "1.5em",
                aspectRatio: 1,
              }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "2em",
          }}
        >
          <p>
            <span>
              {apiDto.method.toUpperCase()} {apiDto.uri}
            </span>
            <span
              style={{
                color: ApiDto.getColourForStatus(apiDto),
                marginLeft: "1em",
              }}
            >
              {ApiDto.getOverallStatus(apiDto)}
            </span>
            <span
              onClick={() => {}}
              style={{
                marginLeft: "1em",
                cursor: "pointer",
              }}
            >
              Every {apiDto.interval_ms / 1000} seconds
              <img
                src={iconEditBlueUrl}
                style={{
                  width: "1.1em",
                  marginBottom: "-0.2em",
                  marginLeft: "0.4em",
                  aspectRatio: 322 / 323,
                }}
              />
            </span>
          </p>
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>
                Headers{" "}
                <CopyButton
                  text={headersStr}
                  style={{
                    marginBottom: "-0.14em",
                    marginLeft: "0.25em",
                  }}
                />
              </p>
              <p
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {headersStr}
              </p>
            </div>
            <div
              style={{
                flex: 1.5,
              }}
            >
              <p>
                Body{" "}
                <CopyButton
                  text={body}
                  style={{
                    marginBottom: "-0.14em",
                    marginLeft: "0.25em",
                  }}
                />
              </p>
              <p
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {body}
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Actions</p>
          <p
            style={{
              fontSize: "0.8em",
              marginTop: "0.25em",
            }}
          >
            Check/uncheck to decide if an action is associated with this API.
            Associated actions get triggered if an API healthcheck fails.
          </p>
          <ActionsTable
            style={{
              marginTop: "0.5em",
            }}
            actions={actionsList}
            connectedActionIds={apiDto.connected_action_ids}
            onActionToggle={async (actionId, checked) => {
              await patchAction(id, actionId, checked);
              await refreshApi();
            }}
          />
        </div>

        <Footer
          style={{
            width: "100%",
            marginTop: "4em",
          }}
        />
      </div>
    </GeneralSkeleton>
  );
};
