import { api } from "../..";
import { SubscriptionPlanProvider } from "../../../store/user";

export interface SubscriptionPlanInfo {
  id: number;
  healthcheck_min_interval_ms: number;
  healthcheck_max_num_apis: number;
  healthcheck_req_data_limit_bytes: number;
  healthcheck_max_timeout_ms: number;
  provider: SubscriptionPlanProvider;
  provider_id: string;
}

export enum SubscriptionStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Overdue = "Overdue",
  Inactive = "Inactive",
}

export interface UserSubscriptionInfo {
  id: number;
  started_at: number;
  ended_at?: number;
  plan: SubscriptionPlanInfo;
  status: SubscriptionStatus;
  provider_id: string;
}

export const getUserSubscriptionInfo = () => {
  return api.get("user-account/subscription").json<UserSubscriptionInfo>();
};
