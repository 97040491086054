import { api } from "../..";

export type StatsResDto = {
  num_apis_total: number;
  num_apis_healthy_now: number;
  num_apis_unhealthy_now: number;
  num_apis_completely_healthy_48hrs: number;
  num_apis_unhealthy_48hrs: number;
};

export type ExecutionStatsResDto = {
  num_api_healthcheck_executions_total: number;
};

export const getUserAccountStats = () => {
  return api.get("user-account/stats").json<StatsResDto>();
};

export const getUserAccountExecutionStats = () => {
  return api.get("user-account/stats/execution").json<ExecutionStatsResDto>();
};
