import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import { ApiRouter } from "./pages/Api";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";

export const DashboardPage = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/api/*" element={<ApiRouter />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
};
