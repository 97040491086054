import { CSSProperties } from "react";
import styles from "./TextAreaInput.module.css";

export const TextAreaInput = ({
  placeholder,
  text,
  onTextChange,
  style,
  suffixIcon,
}: {
  placeholder: string;
  text: string;
  onTextChange: (text: string) => unknown;
  style?: CSSProperties;
  suffixIcon?: JSX.Element;
}) => {
  return (
    <div
      style={{
        border: "2px solid #466befcc",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "1em",
        padding: "1em 0",
        ...(style || {}),
      }}
    >
      <textarea
        className={styles.textInput}
        rows={10}
        style={{
          fontSize: "1em",
          flex: 1,
        }}
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
      />
      {!!suffixIcon && (
        <span
          style={{
            paddingRight: "1em",
          }}
        >
          {suffixIcon}
        </span>
      )}
    </div>
  );
};
