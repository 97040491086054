import { CSSProperties, useEffect, useRef, useState } from "react";
import iconDropdownUrl from "../../assets/icon-dropdown.svg";
import useWindowDimensions from "../../util/hooks/useWindowDimensions";
import styles from "./Dropdown.module.css";

export type DropdownItem = {
  id: string;
  label: string;
  expandedLabel?: string;
};

export const Dropdown = ({
  items,
  onSelectItem,
  defaultOpen = false,
  defaultSelectedItemId,
  style,
  className,
}: {
  items: DropdownItem[];
  onSelectItem: (item: DropdownItem) => unknown;
  defaultOpen?: boolean;
  defaultSelectedItemId?: string;
  style?: CSSProperties;
  className?: string;
}) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const windowDim = useWindowDimensions();
  const selectedItemRef = useRef<HTMLDivElement>(null);

  const scrollToSelectedItem = () => {
    selectedItemRef?.current?.scrollIntoView?.({
      block: "nearest",
    });
  };

  useEffect(() => {
    if (isOpen) {
      scrollToSelectedItem();
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedItem(
      items.find((i) => i.id === defaultSelectedItemId) || items[0],
    );
  }, [items, defaultSelectedItemId]);

  useEffect(() => {
    if (selectedItem) {
      onSelectItem(selectedItem);
    }
  }, [selectedItem, onSelectItem]);

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        zIndex: isOpen ? "1000" : undefined,
        ...(style || {}),
      }}
      className={`${className || ""}`}
      tabIndex={0}
      onBlur={() => {
        setIsOpen(false);
      }}
      onClick={() => {
        setIsOpen((o) => !o);
      }}
    >
      <div
        className={` ${styles.card}`}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "1em 1.5em",
        }}
      >
        <p
          style={{
            flex: 1,
            paddingRight: "1.5em",
            margin: 0,
          }}
        >
          {selectedItem?.label}
        </p>
        <img
          src={iconDropdownUrl}
          style={{
            width: "0.8em",
            aspectRatio: 182.65 / 154.03,
            transform: isOpen ? "rotate(180deg)" : "",
          }}
        />
      </div>
      {isOpen && (
        <div
          className={` ${styles.card}`}
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            top: 0,
            left: 0,
            right: 0,
            padding: "1em 0 0 0",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 1.5em",
            }}
          >
            <p
              style={{
                flex: 1,
                padding: 0,
                margin: 0,
              }}
            >
              {selectedItem?.label}
            </p>
            <img
              src={iconDropdownUrl}
              style={{
                width: "0.8em",
                aspectRatio: 182.65 / 154.03,
                transform: isOpen ? "rotate(180deg)" : "",
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "1em",
              marginBottom: "0.5em",
              padding: "0 1.5em",
              height: "1px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#466BEF99",
              }}
            />
          </div>

          <div
            className={styles.dropdownList}
            style={{
              padding: "0 0",
              maxHeight: windowDim.height * 0.3,
              overflowX: "hidden",
              width: "100%",
              overflowY: "auto",
              scrollbarColor: "#466BEF #000000",
            }}
          >
            {items.map((i) => (
              <div
                key={i.id}
                ref={i.id === selectedItem?.id ? selectedItemRef : null}
                style={{
                  padding: "1em 1.5em",
                  width: "100%",
                  backgroundColor:
                    i.id === selectedItem?.id ? "#466BEF" : undefined,
                }}
                onClick={() => {
                  setSelectedItem(i);
                }}
              >
                <p
                  style={{
                    flex: 1,
                    whiteSpace: "pre-wrap",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {i.expandedLabel || i.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
