import { api } from "../..";

export type UptimeGraphPoint = {
  startTime: number;
  endTime: number;
  totalHealthchecks: number;
  passedHealthchecks: number;
};

export type UptimeGraphData = {
  label: string;
  points: UptimeGraphPoint[];
};

type UptimeGraphPointRes = {
  start_time: number;
  end_time: number;
  total_healthchecks: number;
  passed_healthchecks: number;
};

type UptimeGraphDataRes = {
  label: string;
  points: UptimeGraphPointRes[];
};

export const getUptimeGraphs = async (apiId: number | "all") => {
  return (
    await api.get(`api/${apiId}/uptime-graphs`).json<UptimeGraphDataRes[]>()
  ).map((r) => {
    return {
      label: r.label,
      points: r.points.map((p) => ({
        startTime: p.start_time,
        endTime: p.end_time,
        totalHealthchecks: p.total_healthchecks,
        passedHealthchecks: p.passed_healthchecks,
      })),
    } as UptimeGraphData;
  });
};
