import { api } from "..";

export type LoginResDto =
  | {
      id: number;
      name: string;
      email: string;
      company_name: string;
      token: string;
      status: "Success";
    }
  | {
      reason: string;
      status: "Failure";
    };

export const login = async (email: string, password: string) => {
  return api
    .post("login", {
      json: {
        email,
        password,
      },
    })
    .json<LoginResDto>();
};

export type UserAccountDto = {
  id: number;
  name: string;
  email: string;
  company_name: string;
};

export const getUserAccount = async () => {
  return api.get("user-account").json<UserAccountDto>();
};
