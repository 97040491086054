import React, { CSSProperties } from "react";
import styles from "./TextInput.module.css";

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  text: string;
  onTextChange: (text: string) => unknown;
  style?: CSSProperties;
  suffixIcon?: JSX.Element;
}

export const TextInput = ({
  placeholder,
  text,
  onTextChange,
  style,
  suffixIcon,
  ...rest
}: ITextInputProps) => {
  return (
    <div
      style={{
        border: "2px solid #466befcc",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "1em",
        padding: "1em 0",
        ...(style || {}),
      }}
    >
      <input
        className={styles.textInput}
        style={{
          fontSize: "1em",
          flex: 1,
        }}
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
        type=""
        {...rest}
      />
      {!!suffixIcon && (
        <span
          style={{
            paddingRight: "1em",
          }}
        >
          {suffixIcon}
        </span>
      )}
    </div>
  );
};
