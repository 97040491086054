type Upto = {
  val: number;
  upto?: boolean;
};

type PlanRaw = {
  name: string;
  healthcheckMinIntervalSeconds: Upto;
  healthcheckMaxTimeoutSeconds: Upto;
  maxApis: Upto;
  apiRequestDataLimitBytes: Upto;
  priceUnitsPerMonth: {
    val?: (currency: Currency) => number;
    override?: string;
  };
  actionButton: {
    label: string;
    link: string;
  };
};

export type Currency = "INR" | "USD";

export type PricingPlan = {
  name: string;
  healthcheckMinIntervalSeconds: string;
  healthcheckMaxTimeoutSeconds: string;
  maxApis: string;
  apiRequestDataLimitBytes: string;
  priceUnitsPerMonth: string;
  actionButton: {
    label: string;
    link: string;
  };
};

export const labelsInfo: {
  [key: string]: {
    title: string;
    subtitle?: string;
    formatter:
      | ((data: string) => string)
      | ((data: Upto) => string)
      | ((
          data: { val?: (currency: Currency) => number; override?: string },
          currency: Currency,
        ) => string);
  };
} = {
  name: { title: "Name", formatter: (data: string) => data },
  healthcheckMinIntervalSeconds: {
    title: "Healthcheck min interval",
    formatter: (data: Upto) => {
      let unit = "second",
        divisionFactor = 1;
      if (data.val >= 60) {
        unit = "minute";
        divisionFactor = 60;
      }
      const val = data.val / divisionFactor;
      return `${data.upto ? "Up to every" : "Every"} ${val.toLocaleString()} ${unit}${val !== 1 ? "s" : ""}`;
    },
  },
  healthcheckMaxTimeoutSeconds: {
    title: "Healthcheck max timeout",
    formatter: (data: Upto) =>
      `${data.upto ? "Up to " : ""}${data.val.toLocaleString()} seconds`,
  },
  maxApis: {
    title: "Max # of APIs",
    formatter: (data: Upto) =>
      `${data.upto ? "Up to " : ""}${data.val.toLocaleString()}`,
  },
  apiRequestDataLimitBytes: {
    title: "API request data limit",
    formatter: (data: Upto) =>
      `${data.upto ? "Up to " : ""}${data.val.toLocaleString()} bytes`,
  },
  priceUnitsPerMonth: {
    title: "Price per month",
    subtitle: "(inclusive of everything, like tax)",
    formatter: (
      data: { val?: (currency: Currency) => number; override?: string },
      currency: Currency,
    ) =>
      data.override
        ? data.override
        : `${
            currency === "USD" ? "US$" : "₹"
          }${((data.val?.(currency) || 0) / 100).toLocaleString()}`,
  },
  actionButton: {
    title: "",
    formatter: (d: string) => d,
  },
};

export const labelsOrder = [
  "maxApis",
  "healthcheckMinIntervalSeconds",
  "healthcheckMaxTimeoutSeconds",
  "apiRequestDataLimitBytes",
  "priceUnitsPerMonth",
];

const plansRaw: PlanRaw[] = [
  {
    name: "Starter",
    healthcheckMinIntervalSeconds: { val: 120 },
    healthcheckMaxTimeoutSeconds: { val: 10 },
    maxApis: { val: 15 },
    apiRequestDataLimitBytes: { val: 1024 * 1 },
    priceUnitsPerMonth: {
      val: (currency: Currency) => (currency === "USD" ? 199 : 19900),
    },
    actionButton: {
      label: "buy",
      link: "",
    },
  },
  {
    name: "Starter\nbut kinda big",
    healthcheckMinIntervalSeconds: { val: 60 },
    healthcheckMaxTimeoutSeconds: { val: 45 },
    maxApis: { val: 30 },
    apiRequestDataLimitBytes: { val: 1024 * 1.5 },
    priceUnitsPerMonth: {
      val: (currency: Currency) => (currency === "USD" ? 499 : 39900),
    },
    actionButton: {
      label: "buy",
      link: "",
    },
  },
  {
    name: "Starter\nbut pretty big",
    healthcheckMinIntervalSeconds: { val: 60 },
    healthcheckMaxTimeoutSeconds: { val: 60 },
    maxApis: { val: 50 },
    apiRequestDataLimitBytes: { val: 1024 * 2 },
    priceUnitsPerMonth: {
      val: (currency: Currency) => (currency === "USD" ? 999 : 79900),
    },
    actionButton: {
      label: "buy",
      link: "",
    },
  },
  {
    name: "Starter\nbut arbitrarily big",
    healthcheckMinIntervalSeconds: { val: 30, upto: true },
    healthcheckMaxTimeoutSeconds: { val: 300, upto: true },
    maxApis: { val: 500, upto: true },
    apiRequestDataLimitBytes: { val: 1024 * 50, upto: true },
    priceUnitsPerMonth: { override: "Custom pricing" },
    actionButton: {
      label: "contact",
      link: "mailto:admiral@periscope.sh?subject=I%20am%20interested%20in%20your%20Custom%20Pricing",
    },
  },
];

export const getPlans = (currency: Currency) => {
  return plansRaw.map((planRaw) => {
    const copiedPlanRaw = { ...planRaw } as unknown as {
      [key: string]: unknown;
    };
    const keys = Object.keys(copiedPlanRaw) as unknown as (keyof PricingPlan)[];
    for (const k of keys) {
      copiedPlanRaw[k] = labelsInfo[k].formatter(
        copiedPlanRaw[k] as string &
          Upto & {
            val?: (currency: Currency) => number;
            override?: string;
          },
        currency,
      );
    }
    return copiedPlanRaw as unknown as PricingPlan;
  });
};
