import React from "react";

export type BreadcrumbItem = {
  url: string;
  label: string;
};

export const Breadcrumb = ({ items }: { items: BreadcrumbItem[] }) => {
  return (
    <p
      style={{
        fontSize: "1.5em",
        letterSpacing: "5px",
      }}
    >
      {items.map((item, idx) => {
        const labelComponent =
          idx === items.length - 1 ? (
            <>
              {item.label.substring(0, item.label.length - 1)}
              <span
                style={{
                  letterSpacing: "0px",
                }}
              >
                {item.label.substring(item.label.length - 1)}
              </span>
            </>
          ) : (
            <>{item.label}</>
          );
        return (
          <React.Fragment key={idx}>
            <a
              href={item.url}
              style={{
                textDecoration:
                  idx === items.length - 1 && items.length > 1
                    ? "underline"
                    : "none",
                textUnderlineOffset: "3px",
                textDecorationThickness: "1px",
              }}
            >
              {labelComponent}
            </a>
            {idx < items.length - 1 && <span>&nbsp;/&nbsp;</span>}
          </React.Fragment>
        );
      })}
    </p>
  );
};
