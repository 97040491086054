import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../store/user";

export const UserInfo = () => {
  const [companyName, email, fetchUserInfo] = useUserStore(
    useShallow((state) =>
      state?.info
        ? [state.info.companyName, state.info.email, state.fetchUserInfo]
        : ["", "", state.fetchUserInfo],
    ),
  );

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <p>{email}</p>
      <p>{companyName}</p>
    </div>
  );
};
