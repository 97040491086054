import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ApiMiniDto, listApis } from "../api/api";

export interface ApiState {
  apisList: ApiMiniDto[];
  fetchApisList: () => Promise<void>;
}

export const useApiStore = create<ApiState>()(
  devtools(
    persist(
      (set) => ({
        apisList: [],
        fetchApisList: async () => {
          const res = await listApis();
          set({
            apisList: res,
          });
        },
      }),
      {
        name: "api-storage",
      },
    ),
  ),
);
