import { api } from "..";
import {
  ApiHealthcheckActionStatus,
  ApiHealthcheckActionTypeData,
} from "../api";

export type ApiActionMiniDto = {
  id: number;
  name: string;
  status: ApiHealthcheckActionStatus;
  typ: ApiHealthcheckActionTypeData;
};

export const listActions = async () => {
  return api.get(`action`).json<ApiActionMiniDto[]>();
};

export const patchAction = async (
  apiId: number,
  actionId: number,
  connect: boolean,
) => {
  await api.patch(`action/${actionId}`, {
    json: {
      api_id: apiId,
      connect,
    },
  });
};
