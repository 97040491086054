import styles from "./IconButton.module.css";

interface IconButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  disabled?: boolean;
  suffixIcon?: JSX.Element;
}

export const IconButton = ({
  className,
  children,
  suffixIcon,
  disabled,
  ...rest
}: IconButtonProps) => {
  return (
    <div
      className={`${className} ${styles.btn} ${disabled ? styles.disabled : ""}`}
      {...rest}
    >
      <span
        style={{
          paddingBottom: "0.2em",
        }}
      >
        {children}
      </span>
      {!!suffixIcon && (
        <span
          style={{
            paddingLeft: "0.5em",
          }}
        >
          {suffixIcon}
        </span>
      )}
    </div>
  );
};
