import { useCallback, useEffect, useMemo, useState } from "react";
import {
  UptimeGraphData,
  getUptimeGraphs,
} from "../../../api/api/uptime-graphs";
import { useApiStore } from "../../../store/api";
import { Dropdown, DropdownItem } from "../../components/Dropdown";
import { Footer } from "../../components/Footer";
import { GeneralSkeleton } from "../../components/GeneralSkeleton";
import { UptimeGraph } from "../../components/UptimeGraph";

export const HomePage = () => {
  const [selectedApiId, setSelectedApiId] = useState<number | "all">("all");
  const [apisList, fetchApisList] = useApiStore((state) => [
    state.apisList,
    state.fetchApisList,
  ]);
  const [uptimeGraphData, setUptimeGraphData] = useState<UptimeGraphData[]>([]);

  const dropdownChoices = useMemo(() => {
    return [
      {
        id: "all",
        label: "All APIs",
      },
      ...apisList.map((api) => ({
        id: api.id.toString(),
        label: `API ID ${api.id}`,
        expandedLabel: `API ID ${api.id}:\n${api.method} ${api.uri}`,
      })),
    ];
  }, [apisList]);

  useEffect(() => {
    fetchApisList();
  }, [fetchApisList]);

  const refreshUptimeGraphs = useCallback(async () => {
    setUptimeGraphData(await getUptimeGraphs(selectedApiId));
  }, [selectedApiId]);

  useEffect(() => {
    refreshUptimeGraphs();
    const interval = setInterval(() => {
      refreshUptimeGraphs();
    }, 30000);
    return () => clearInterval(interval);
  }, [refreshUptimeGraphs]);

  const onSelectItemDropdown = useCallback((item: DropdownItem) => {
    setSelectedApiId(item.id === "all" ? "all" : parseInt(item.id));
  }, []);

  return (
    <GeneralSkeleton>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Dropdown
          style={{
            width: "100%",
          }}
          items={dropdownChoices}
          defaultSelectedItemId="all"
          onSelectItem={onSelectItemDropdown}
        />
        <div style={{}}>
          {uptimeGraphData.map((g) => (
            <div
              key={`UGL_${g.label}`}
              style={{
                marginTop: "2em",
              }}
            >
              <p
                style={{
                  fontSize: "0.8em",
                  marginBottom: "0.75em",
                }}
              >
                {g.label}
              </p>
              <UptimeGraph graphPoints={g.points} />
            </div>
          ))}
        </div>
        <div
          style={{
            flex: 1,
          }}
        />
        <Footer
          style={{
            width: "100%",
          }}
        />
      </div>
    </GeneralSkeleton>
  );
};
