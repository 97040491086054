import { useMemo, useState } from "react";
import logoWhiteUrl from "../../../assets/logo-white.svg";
import { GlassCard } from "../../../components/GlassCard";
import { IconButton } from "../../../components/IconButton";
import { useUserStore } from "../../../store/user";
import { TextInput } from "../../components/TextInput";
import { Toaster } from "react-hot-toast";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = useUserStore((state) => state.login);

  const isValidInput = useMemo(() => {
    const emailRegex =
      /^[a-zA-Z0-9]+([._-][0-9a-zA-Z]+)*@[a-zA-Z0-9]+([.-][0-9a-zA-Z]+)*\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email) && password.length > 0;
  }, [email, password]);

  const onLoginClick = async () => {
    await login(email, password);
    window.location.assign("/");
  };

  return (
    <div
      style={{
        backgroundColor: "#000000",
        height: "100%",
        width: "100%",
        color: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          height: "100px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1em 3em",
          zIndex: 100,
        }}
      >
        <img
          style={{
            padding: "0.8em 0",
            height: "100%",
          }}
          src={logoWhiteUrl}
        />
      </div>

      <div
        style={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GlassCard
          style={{
            width: "400px",
            maxWidth: "95vw",
            marginBottom: "4em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              padding: "2em",
            }}
          >
            <p
              style={{
                fontSize: "1.2em",
              }}
            >
              Login to your account
            </p>
            <TextInput
              style={{
                marginTop: "1em",
                width: "100%",
              }}
              placeholder="john@example.org"
              text={email}
              onTextChange={setEmail}
              type="email"
            />
            <TextInput
              style={{
                marginTop: "1em",
                width: "100%",
              }}
              placeholder="****"
              text={password}
              onTextChange={setPassword}
              type="password"
            />
            <IconButton
              disabled={!isValidInput}
              onClick={onLoginClick}
              style={{
                marginTop: "1em",
              }}
            >
              Login
            </IconButton>
          </div>
        </GlassCard>
      </div>

      <Toaster />
    </div>
  );
};
