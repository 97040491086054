import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ApiActionMiniDto, listActions } from "../api/action";

export interface ActionState {
  actionsList: ApiActionMiniDto[];
  fetchActionsList: () => Promise<void>;
}

export const useActionStore = create<ActionState>()(
  devtools(
    persist(
      (set) => ({
        actionsList: [],
        fetchActionsList: async () => {
          const res = await listActions();
          set({
            actionsList: res,
          });
        },
      }),
      {
        name: "action-storage",
      },
    ),
  ),
);
