import { api } from "..";

export enum ApiStatus {
  Active = "Active",
  Paused = "Paused",
  Inactive = "Inactive",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApiStatus {
  export function getIndex(status: ApiStatus) {
    return status === ApiStatus.Active
      ? -1
      : status === ApiStatus.Paused
        ? 0
        : 1;
  }

  export function toColour(status: ApiStatus) {
    return status === ApiStatus.Active
      ? "#00CE5F"
      : status === ApiStatus.Paused
        ? "#FF820E"
        : "#FF6363";
  }
}

export enum ApiHealthStatus {
  Healthy = "Healthy",
  Unhealthy = "Unhealthy",
  Unknown = "Unknown",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApiHealthStatus {
  export function getIndex(status: ApiHealthStatus) {
    return status === ApiHealthStatus.Unhealthy
      ? -1
      : status === ApiHealthStatus.Unknown
        ? 0
        : 1;
  }

  export function toColour(status: ApiHealthStatus) {
    return status === ApiHealthStatus.Healthy
      ? "#00CE5F"
      : status === ApiHealthStatus.Unknown
        ? "#ACACAC"
        : "#FF6363";
  }
}

export enum ApiMethod {
  Options = "Options",
  Get = "Get",
  Post = "Post",
  Put = "Put",
  Delete = "Delete",
  Head = "Head",
  Patch = "Patch",
}

export enum ApiHealthcheckActionStatus {
  Active = "Active",
  Paused = "Paused",
  Inactive = "Inactive",
}

export type ApiHealthcheckActionTypeData =
  | {
      t: "Slack";
      c: {
        url: string;
      };
    }
  | {
      t: "Email";
      c: {
        to_address: string;
      };
    };

export interface ApiMiniDto {
  id: number;
  health_status: ApiHealthStatus;
  status: ApiStatus;
  interval_ms: number;
  method: ApiMethod;
  uri: string;
}

export type CreateApiReqDto = {
  interval_ms: number;
  method: ApiMethod;
  uri: string;
  body: string;
  headers: { [key: string]: string };
  connected_action_ids: number[];
};

export interface ApiDto extends ApiMiniDto {
  body: string;
  headers: string;
  connected_action_ids: number[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApiDto {
  export function getOverallStatus(dto: ApiMiniDto) {
    return dto.status === ApiStatus.Active ? dto.health_status : dto.status;
  }

  export function getColourForStatus(dto: ApiMiniDto) {
    return dto.status === ApiStatus.Active
      ? ApiHealthStatus.toColour(dto.health_status)
      : ApiStatus.toColour(dto.status);
  }
}

export const listApis = async () => {
  return api.get("api").json<ApiMiniDto[]>();
};

export const getApi = async (apiId: number) => {
  return api.get(`api/${apiId}`).json<ApiDto>();
};

export const createApi = (dto: CreateApiReqDto) => {
  return api
    .post(`api`, {
      json: dto,
    })
    .json<{ id: number }>();
};

export const patchApi = async (apiId: number, pause: boolean) => {
  await api.patch(`api/${apiId}`, {
    json: {
      pause,
    },
  });
};

export const deleteApi = async (apiId: number) => {
  await api.delete(`api/${apiId}`);
};
