import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiMethod, createApi } from "../../../../api/api";
import { Checkbox } from "../../../../components/Checkbox";
import { IconButton } from "../../../../components/IconButton";
import { useActionStore } from "../../../../store/action";
import { ActionsTable } from "../../../components/ActionsTable";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Dropdown, DropdownItem } from "../../../components/Dropdown";
import { GeneralSkeleton } from "../../../components/GeneralSkeleton";
import { TextAreaInput } from "../../../components/TextAreaInput";
import { TextInput } from "../../../components/TextInput";
import { useUserStore } from "../../../../store/user";

export const CreateApiPage = () => {
  const [method, setMethod] = useState(ApiMethod.Get);
  const [intervalSeconds, setIntervalSeconds] = useState(60);
  const [actionsList, fetchActionsList] = useActionStore((state) => [
    state.actionsList,
    state.fetchActionsList,
  ]);

  useEffect(() => {
    fetchActionsList();
  }, [fetchActionsList]);

  const methodItems = useMemo(
    () =>
      ["GET", "POST", "PUT", "PATCH", "DELETE"].map((method) => ({
        id: method.toLowerCase(),
        label: method,
      })),
    [],
  );
  const subscriptionInfo = useUserStore((state) => state.subscriptionInfo);
  const intervalItems = useMemo(
    () =>
      [300, 240, 180, 120, 90, 60, 30]
        .sort((a, b) => a - b)
        .filter(
          (secs) =>
            secs >=
            (subscriptionInfo?.plan?.healthcheckMinIntervalMs || 0) / 1000,
        )
        .map((time) => {
          return {
            id: `${time}s`,
            label: `Every ${time} seconds`,
          };
        }),
    [subscriptionInfo],
  );

  const [url, setUrl] = useState("");
  const urlErrorMessage = useMemo(() => {
    try {
      const urlObj = new URL(url);
      return urlObj.protocol === "https:" ? "" : "We only accept HTTPS urls";
    } catch (_) {
      return "This URL is not valid";
    }
  }, [url]);

  const [body, setBody] = useState("");
  const [headers, setHeaders] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [isJsonFlag, setIsJsonFlag] = useState(false);
  const isValidJson = useMemo(() => {
    if (!isJsonFlag) {
      return false;
    }
    try {
      return !!body && !!JSON.parse(body);
    } catch {
      return false;
    }
  }, [isJsonFlag, body]);

  const canSubmit = useMemo(() => {
    return (!isJsonFlag || isValidJson) && !urlErrorMessage;
  }, [isValidJson, isJsonFlag, urlErrorMessage]);

  const [isActionConnected, setIsActionConnected] = useState<{
    [key: number]: boolean;
  }>({});
  const connectedActionIds = useMemo(() => {
    return Object.entries(isActionConnected)
      .filter(([, v]) => v)
      .map(([k]) => parseInt(k));
  }, [isActionConnected]);

  const navigate = useNavigate();

  useEffect(() => {
    let idxToRemove = -1;
    headers.forEach((header, idx) => {
      if (!header.key && !header.value) {
        idxToRemove = idx;
      }
    });

    if (idxToRemove >= 0) {
      setHeaders(headers.filter((_, idx) => idx !== idxToRemove));
    }
  }, [headers]);

  useEffect(() => {
    const contentTypeHeader = headers.find(
      (h) => h.key.toLowerCase() === "content-type",
    );
    setIsJsonFlag(contentTypeHeader?.value === "application/json");
  }, [headers]);

  useEffect(() => {
    setHeaders((currentHeaders) => {
      const contentTypeHeaderIdx = currentHeaders.findIndex(
        (h) => h.key.toLowerCase() === "content-type",
      );
      if (isJsonFlag) {
        if (contentTypeHeaderIdx >= 0) {
          currentHeaders[contentTypeHeaderIdx].value = "application/json";
        } else {
          currentHeaders.push({
            key: "Content-Type",
            value: "application/json",
          });
        }
      } else if (
        currentHeaders[contentTypeHeaderIdx]?.value === "application/json"
      ) {
        currentHeaders = currentHeaders.filter(
          (_, idx) => idx !== contentTypeHeaderIdx,
        );
      }
      return [...currentHeaders];
    });
  }, [isJsonFlag]);

  const onContinue = async () => {
    const { id } = await createApi({
      body,
      headers: Object.fromEntries(headers.map((h) => [h.key, h.value])),
      interval_ms: intervalSeconds * 1000,
      method,
      uri: url,
      connected_action_ids: Object.entries(isActionConnected)
        .filter(([, v]) => v)
        .map(([k]) => parseInt(k)),
    });
    navigate(`/api/${id}`);
  };

  const onSelectMethod = useCallback((i: DropdownItem) => {
    setMethod(
      (i.label.charAt(0).toUpperCase() +
        i.label.slice(1).toLowerCase()) as ApiMethod,
    );
  }, []);

  const onSelectInterval = useCallback((i: DropdownItem) => {
    setIntervalSeconds(parseInt(i.id.slice(0, i.id.length - 1)));
  }, []);

  return (
    <GeneralSkeleton
      containerStyle={{
        paddingBottom: 0,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            items={[
              {
                url: "/api",
                label: "your apis",
              },
              {
                url: "",
                label: "new",
              },
            ]}
          />
        </div>
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            overflowY: "auto",
            scrollbarColor: "#466BEF #000000",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              marginTop: "1.5em",
              width: "100%",
            }}
          >
            <Dropdown
              items={methodItems}
              defaultSelectedItemId="GET"
              onSelectItem={onSelectMethod}
            />

            <div
              style={{
                display: "flex",
                marginLeft: "1.5em",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <TextInput
                style={{}}
                placeholder="https://google.com"
                text={url}
                onTextChange={(newUrl) => {
                  setUrl(newUrl);
                }}
              />
              {!!url && !!urlErrorMessage && (
                <p
                  style={{
                    fontSize: "0.8em",
                    color: "#FF9800",
                    marginTop: "1em",
                    marginLeft: "0.5em",
                  }}
                >
                  {urlErrorMessage}
                </p>
              )}
            </div>
          </div>
          <Dropdown
            style={{
              marginTop: "1em",
            }}
            defaultSelectedItemId="60s"
            items={intervalItems}
            onSelectItem={onSelectInterval}
          />

          <p
            style={{
              marginTop: "2em",
            }}
          >
            Headers
          </p>
          {Array(headers.length + 1)
            .fill(0)
            .map((_, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.75em",
                }}
              >
                <TextInput
                  style={{
                    flex: 2,
                  }}
                  placeholder="Key"
                  text={idx >= headers.length ? "" : headers[idx].key}
                  onTextChange={(newKey) => {
                    const newHeaders = [...headers];
                    if (idx >= newHeaders.length) {
                      newHeaders.push({
                        key: "",
                        value: "",
                      });
                    }
                    newHeaders[idx].key = newKey;
                    setHeaders(newHeaders);
                  }}
                />
                <TextInput
                  style={{
                    flex: 3,
                    marginLeft: "1.5em",
                  }}
                  placeholder="Value"
                  text={idx >= headers.length ? "" : headers[idx].value}
                  onTextChange={(newValue) => {
                    const newHeaders = [...headers];
                    if (idx >= newHeaders.length) {
                      newHeaders.push({
                        key: "",
                        value: "",
                      });
                    }
                    newHeaders[idx].value = newValue;
                    setHeaders(newHeaders);
                  }}
                />
              </div>
            ))}

          <div
            style={{
              marginTop: "2em",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "end",
            }}
          >
            <p
              style={{
                marginRight: "3em",
                marginBottom: 0,
              }}
            >
              Body
            </p>
            <Checkbox
              checked={isJsonFlag}
              onToggleCheck={(checked) => setIsJsonFlag(checked)}
              label="Is JSON?"
            />
          </div>
          <TextAreaInput
            placeholder={`{\n  "someImportantData": "ABCD"\n}`}
            text={body}
            onTextChange={(newBody) => setBody(newBody)}
            style={{
              width: "100%",
              marginTop: "0.5em",
            }}
          />
          {isJsonFlag && !isValidJson && (
            <p
              style={{
                marginLeft: "0.5em",
                marginTop: "1em",
                fontSize: "0.8em",
                color: "#FF9800",
              }}
            >
              This is not valid JSON (you mentioned that this body is a JSON).
              However, you can still create the API.
            </p>
          )}
          <p
            style={{
              marginTop: "2em",
              marginBottom: 0,
            }}
          >
            Actions
          </p>
          <ActionsTable
            style={{
              width: "100%",
              marginTop: "0.5em",
            }}
            actions={actionsList}
            connectedActionIds={connectedActionIds}
            onActionToggle={async (actionId, checked) => {
              isActionConnected[actionId] = checked;
              setIsActionConnected({
                ...isActionConnected,
              });
            }}
          />
          <IconButton
            style={{
              marginTop: "2em",
              marginBottom: "3em",
            }}
            disabled={!canSubmit}
            onClick={onContinue}
          >
            create
          </IconButton>
        </div>
      </div>
    </GeneralSkeleton>
  );
};
