import styles from "./GlassCard.module.css";

export const GlassCard = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div className={`${className} ${styles.cardOuter}`} {...rest}>
      <div className={styles.cardInner}>{children}</div>
    </div>
  );
};
