import { CSSProperties } from "react";
import iconCheckboxCheckedBlueUrl from "../assets/icon-checkbox-checked-blue.svg";
import iconCheckboxUncheckedBlueUrl from "../assets/icon-checkbox-unchecked-blue.svg";

export const Checkbox = ({
  checked,
  onToggleCheck,
  label,
  style,
  className,
  loading,
}: {
  checked: boolean;
  onToggleCheck: (checked: boolean) => unknown;
  label: string;
  style?: CSSProperties;
  className?: string;
  loading?: boolean;
}) => {
  return (
    <div
      className={`${className || ""}`}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...(style || {}),
      }}
    >
      <img
        onClick={() => onToggleCheck(!checked)}
        src={
          checked ? iconCheckboxCheckedBlueUrl : iconCheckboxUncheckedBlueUrl
        }
        style={{
          width: "1em",
          aspectRatio: 1,
          cursor: "pointer",
          opacity: loading ? "0.7" : "1",
          marginRight: "0.5em",
        }}
      />

      <p
        style={{
          margin: 0,
        }}
      >
        {label}
      </p>
    </div>
  );
};
