import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { GlassCard } from "../../components/GlassCard";

export type TableRow = {
  data: {
    [columnId: string]: {
      text: string | number | boolean | JSX.Element;
      color?: string;
    };
  };
  url?: string;
};

export type TableProps = {
  style?: CSSProperties;
  className?: string;
  columns: {
    id: string;
    title: string;
    subtitle: string;
    weight: number;
  }[];
  rows: TableRow[];
};

export const Table = ({ style, className, columns, rows }: TableProps) => {
  const navigate = useNavigate();
  return (
    <GlassCard
      className={`${className || ""}`}
      style={{
        ...(style || {}),
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderTopLeftRadius: "calc(1em - 2px)",
            borderTopRightRadius: "calc(1em - 2px)",
            backgroundColor: "#466BEF12",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "1em 0",
            borderBottom: "1px solid #466BEF80",
            fontSize: "1.2em",
            scrollbarGutter: "stable",
            overflowY: "scroll",
            scrollbarColor: "#00000000 #00000000",
          }}
        >
          {columns.map((column) => (
            <p
              key={column.id}
              style={{
                flex: column.weight,
                padding: "0 1rem",
                marginBottom: 0,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                overflow: "hidden",
              }}
            >
              {column.title}
              {column.subtitle && (
                <>
                  <br />
                  <span
                    style={{
                      fontSize: "0.8em",
                    }}
                  >
                    {column.subtitle}
                  </span>
                </>
              )}
            </p>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
            scrollbarGutter: "stable",
            scrollbarColor: "#466BEF #000000",
          }}
        >
          {rows.map((row, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "1em 0",
                borderBottom: "1px solid #466BEF80",
                cursor: row.url ? "pointer" : undefined,
              }}
              onClick={() => {
                if (row.url) {
                  navigate(row.url);
                }
              }}
            >
              {columns.map((column) => (
                <p
                  key={column.id}
                  style={{
                    flex: column.weight,
                    padding: "0 1rem",
                    marginBottom: 0,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                  }}
                >
                  <span
                    style={{
                      color: row.data[column.id].color || "#FFFFFF",
                    }}
                  >
                    {row.data[column.id].text}
                  </span>
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </GlassCard>
  );
};
