import { CSSProperties, useMemo, useState } from "react";
import { ApiActionMiniDto } from "../../api/action";
import iconCheckboxCheckedBlueUrl from "../../assets/icon-checkbox-checked-blue.svg";
import iconCheckboxUncheckedBlueUrl from "../../assets/icon-checkbox-unchecked-blue.svg";
import { Table } from "./Table";

export const ActionsTable = ({
  style,
  className,
  connectedActionIds,
  actions: actionsProp,
  onActionToggle,
}: {
  style?: CSSProperties;
  className?: string;
  connectedActionIds: number[];
  actions: ApiActionMiniDto[];
  onActionToggle: (actionId: number, checked: boolean) => Promise<unknown>;
}) => {
  const [loading, setLoading] = useState(false);
  const [actionCheckedOverride, setActionCheckedOverride] = useState<
    | {
        actionId: number;
        checked: boolean;
      }
    | undefined
  >();
  const actions = useMemo(() => {
    return actionsProp.sort((a1, a2) => a1.id - a2.id);
  }, [actionsProp]);

  const isActionConnected = useMemo(() => {
    return Object.fromEntries(connectedActionIds.map((id) => [id, true])) as {
      [id: number]: boolean;
    };
  }, [connectedActionIds]);

  const onCheckboxClick = async (action: ApiActionMiniDto) => {
    try {
      const newIsConnected = !isActionConnected[action.id];
      setActionCheckedOverride({
        actionId: action.id,
        checked: newIsConnected,
      });
      setLoading(true);
      await onActionToggle(action.id, newIsConnected);
    } finally {
      setLoading(false);
      setActionCheckedOverride(undefined);
    }
  };

  return (
    <Table
      className={`${className || ""}`}
      style={{
        paddingBottom: "2.5em",
        ...(style || {}),
      }}
      columns={[
        {
          id: "checkbox",
          title: "",
          subtitle: "",
          weight: 1.5,
        },
        {
          id: "id",
          title: "id",
          subtitle: "",
          weight: 1,
        },
        {
          id: "status",
          title: "status",
          subtitle: "",
          weight: 3,
        },
        {
          id: "name",
          title: "name",
          subtitle: "",
          weight: 10,
        },
      ]}
      rows={actions.map((action) => {
        const isConnected =
          actionCheckedOverride?.actionId === action.id
            ? actionCheckedOverride.checked
            : isActionConnected[action.id];
        return {
          data: {
            checkbox: {
              text: (
                <img
                  onClick={() => onCheckboxClick(action)}
                  src={
                    isConnected
                      ? iconCheckboxCheckedBlueUrl
                      : iconCheckboxUncheckedBlueUrl
                  }
                  style={{
                    width: "1em",
                    aspectRatio: 1,
                    marginBottom: "-0.1em",
                    cursor: "pointer",
                    opacity: loading ? "0.7" : "1",
                  }}
                />
              ),
            },
            id: { text: action.id },
            status: { text: action.status },
            name: { text: action.name },
          },
        };
      })}
    />
  );
};
