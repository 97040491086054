import React, { CSSProperties, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import logoWhiteUrl from "../../assets/logo-white.svg";
import { ApiReport } from "./ApiReport";
import styles from "./GeneralSkeleton.module.css";
import { UserInfo } from "./UserInfo";
import { Toaster } from "react-hot-toast";
import { useUserStore } from "../../store/user";

export const GeneralSkeleton = ({
  containerStyle,
  children,
}: {
  containerStyle?: CSSProperties;
  children?: React.ReactNode;
}) => {
  const location = useLocation();
  const routes = useMemo(
    () => [
      {
        url: "/",
        label: "overall",
      },
      // {
      //   url: "/billing",
      //   label: "billing",
      // },
      {
        url: "/api",
        label: "your apis",
      },
      // {
      //   url: "/action",
      //   label: "your actions",
      // },
    ],
    [],
  );
  const currentRoute = useMemo(() => {
    const matchingRoutes = routes.filter((r) =>
      location.pathname.includes(r.url),
    );
    return matchingRoutes[matchingRoutes.length - 1];
  }, [routes, location.pathname]);

  const { fetchUserSubscriptionInfo } = useUserStore();

  useEffect(() => {
    fetchUserSubscriptionInfo();
  }, [fetchUserSubscriptionInfo]);

  const logout = () => {
    localStorage.clear();
    window.location.assign("/login");
  };

  return (
    <div
      style={{
        backgroundColor: "#000000",
        height: "100%",
        width: "100%",
        color: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={styles.header}>
        <img className={styles.logo} src={logoWhiteUrl} />
        <UserInfo />
      </div>
      <div
        style={{
          flex: 1,
          minHeight: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "20%",
            minWidth: "250px",
            maxWidth: "350px",
            paddingLeft: "3em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ApiReport />
          <div className={styles.links}>
            {routes.map((route) => (
              <React.Fragment key={`ROUTE_${route.url}`}>
                <p
                  style={
                    route.url === currentRoute?.url
                      ? {
                          textDecoration: "underline",
                          textDecorationThickness: "1px",
                          textUnderlineOffset: "3px",
                        }
                      : {}
                  }
                >
                  <Link to={route.url}>{route.label}</Link>
                </p>
              </React.Fragment>
            ))}
            <p>
              <a href="#" onClick={logout}>
                logout
              </a>
            </p>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            flex: 1,
            padding: "2em 3em",
            ...(containerStyle || {}),
          }}
        >
          {children}
        </div>
      </div>
      <Toaster />
    </div>
  );
};
