import { CSSProperties } from "react";
import iconCopyBlueUrl from "../../assets/icon-copy-blue.svg";

export const CopyButton = ({
  text,
  style,
  className,
}: {
  text: string;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <img
      src={iconCopyBlueUrl}
      onClick={() => {
        navigator.clipboard.writeText(text);
      }}
      className={`${className || ""}`}
      style={{
        height: "1.1em",
        aspectRatio: 183 / 247,
        cursor: "pointer",
        ...(style || {}),
      }}
    />
  );
};
