import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiDto,
  ApiHealthStatus,
  ApiMiniDto,
  ApiStatus,
} from "../../../../api/api";
import iconPlusWhiteUrl from "../../../../assets/icon-plus-white.svg";
import iconSearchUrl from "../../../../assets/icon-search.svg";
import { IconButton } from "../../../../components/IconButton";
import { useApiStore } from "../../../../store/api";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Footer } from "../../../components/Footer";
import { GeneralSkeleton } from "../../../components/GeneralSkeleton";
import { Table } from "../../../components/Table";
import { TextInput } from "../../../components/TextInput";

export const ListApiPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [apisList, fetchApisList] = useApiStore((state) => [
    state.apisList,
    state.fetchApisList,
  ]);
  const apisObj = useMemo(() => {
    const obj: { [key: number]: ApiMiniDto } = {};
    apisList.forEach((api) => {
      obj[api.id] = api;
    });
    return obj;
  }, [apisList]);
  const apisStringifiedList = useMemo(() => {
    return apisList.map((api) => ({
      id: api.id,
      str: JSON.stringify(api),
    }));
  }, [apisList]);
  const filteredApisList = useMemo(() => {
    if (!searchTerm) {
      return [...apisList];
    }
    return apisStringifiedList
      .filter((api) => {
        return api.str.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map((api) => apisObj[api.id]);
  }, [searchTerm, apisList, apisStringifiedList, apisObj]);

  useEffect(() => {
    fetchApisList();
  }, [fetchApisList]);

  return (
    <GeneralSkeleton>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Breadcrumb
            items={[
              {
                url: "",
                label: "your apis",
              },
            ]}
          />
          <div
            style={{
              flex: 1,
            }}
          />
          <IconButton
            onClick={() => {
              navigate("/api/new");
            }}
            suffixIcon={
              <img
                src={iconPlusWhiteUrl}
                style={{
                  width: "0.8em",
                  aspectRatio: 1,
                }}
              />
            }
          >
            new
          </IconButton>
          <TextInput
            placeholder="Search"
            text={searchTerm}
            onTextChange={setSearchTerm}
            style={{
              marginLeft: "1em",
              borderRadius: "9999px",
              padding: undefined,
            }}
            suffixIcon={
              <img
                src={iconSearchUrl}
                style={{
                  width: "0.9em",
                  aspectRatio: 1,
                }}
              />
            }
          />
        </div>
        <Table
          style={{
            marginTop: "1.25em",
            marginBottom: "1.5em",
            flex: 1,
            minHeight: 0,
          }}
          columns={[
            {
              id: "id",
              title: "id",
              subtitle: "",
              weight: 1,
            },
            {
              id: "status",
              title: "status",
              subtitle: "",
              weight: 2,
            },
            {
              id: "interval",
              title: "interval",
              subtitle: "",
              weight: 2,
            },
            {
              id: "method",
              title: "method",
              subtitle: "",
              weight: 2,
            },
            {
              id: "url",
              title: "url",
              subtitle: "",
              weight: 4,
            },
          ]}
          rows={filteredApisList
            .sort((a, b) => {
              if (a.status === b.status) {
                if (a.health_status === b.health_status) {
                  return a.id - b.id;
                }
                return (
                  ApiHealthStatus.getIndex(a.health_status) -
                  ApiHealthStatus.getIndex(b.health_status)
                );
              }
              return (
                ApiStatus.getIndex(a.status) - ApiStatus.getIndex(b.status)
              );
            })
            .map((api) => ({
              data: {
                id: { text: api.id.toString() },
                status: {
                  text: ApiDto.getOverallStatus(api),
                  color: ApiDto.getColourForStatus(api),
                },
                interval: { text: `${api.interval_ms / 1000} seconds` },
                method: { text: api.method.toUpperCase() },
                url: { text: api.uri },
              },
              url: `/api/${api.id}`,
            }))}
        />
        <Footer
          style={{
            width: "100%",
          }}
        />
      </div>
    </GeneralSkeleton>
  );
};
